/* Material Design Icons */
@import '~material-design-icons/iconfont/material-icons.css';

/* Materialize CSS */
@import '~materialize-css/dist/css/materialize.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Navbar logo */
.nav-wrapper .brand-logo img {
    height: 2.1rem; /* h1 in brand logo is 2.1rem */
    margin-right: 10px;
}


/* Usercentrics button */
div #usercentrics-root {
  position: absolute;
  transform: scale(0.5, 0.5);
  -ms-transform: scale(0.5, 0.5); /* IE 9 */
  -webkit-transform: scale(0.5, 0.5); /* Safari and Chrome */
  -o-transform: scale(0.5, 0.5); /* Opera */
  -moz-transform: scale(0.5, 0.5); /* Firefox */
}